import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductList from "../components/products/product_list"
import CategoryTitle from "../components/index/categorytitle"


const Urunler = () =>{
  
return (
  <Layout>
    <SEO title="Ürünlerimiz" />
    <div id="urunler" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15 col-xs-w100">
        <CategoryTitle title="SERENAY" subTitle="Ürünler" description="Ürünler" isDark={false} position="inline" />
        <ProductList card={true} />
      </div>
    </div>
  </Layout>
)
}

export default Urunler
